@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@600&family=Poppins:wght@300;400;500;600;700&family=Prosto+One&family=Rubik+Bubbles&display=swap');

* {
  margin: 0;
  padding: 0;
  /* width: auto; */
}

/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
} */


.homeButtonsCinemaUnit2ChoiceBoard {
  background-color: green;
  border-radius: 50px;
  /* margin: auto; */
  border: 7px solid black;
  
}

.image1HomeChinemaUnit2ChoiceBoard {
  width: 90%;
  margin-top: 20px;
  border: 5px solid black;
  border-color: black;
}

.Button1HomeCinemaUnit2ChoiceBoard {
  margin: auto;
  padding-left: 30%;
  padding-right: 30%;
  background-color: white;
}

.homeButtonsCinemaUnit2ChoiceBoard:hover {
  opacity: 50%;
  cursor: pointer;
}

.HomeTextUnit2ChoiceBoard {
  font-family: 'Rubik Bubbles', cursive;
  font-size: 500%;
  text-align: center;
  /* margin: 0%; */
  padding: 30px;
}

.HomeNameUnit2ChoiceBoard {
  font-family: 'Prosto One', cursive;
  text-align: center;
  font-size: 200%;
}

.HomeCinemaUnit2 {
  background-color: #f5f3f1;
  /* padding: 0%;
  margin: 0%; */
}

.line1Unit2ChoiceBoard {
  /* width: 70%; */
  /* align-items: center; */
  /* padding-left: 15%; */
  /* padding-right: 15%; */
  margin-left: 15%;
  margin-right: 15%;
  margin-top: 50px;
  margin-bottom: 10px;
}


.introTextUnit2ChoiceBoard {
  background-color: #f5f3f1;
  padding: 50px;
}

.linksandButtonsHomeCinemaUnit2ChoiceBoard {
  background-color: white;
  padding: 30px;
  font-family: 'Prosto One', cursive;

  
}



.homeComputerIntroTextCinemaUnit2ChoiceBoard {
  color: #a88d2e;
  font-size: 150%;
  text-align: center;
  margin-top: 40px;
  margin-bottom: 60px;
}

.wrapper1HomeUnit2ChoiceBoard {
  display: grid;
  grid-template: auto / 50% 50%;
  grid-gap: 10px;
  /* background-color: #2196F3; */
  padding: 10px;
}

/* .computerIMGUnit2ChoiceBoardWrapper {
  
} */

.computerIMGUnit2ChoiceBoard {
  max-width: 60%;
  /* align-items: center;
  align-content: center; */
  display: block;
  float: right;
  margin-right: 15%;
  /* margin-left: auto;
  margin-right: auto; */
  
  border: 4px solid black;
}


.textComputer2Unit2ChocieBoard {
  margin-left: 5%;
  margin-right: 10%;
  margin-top: 5%; 
  display: block;
  font-size: 18px;
  font-family:sans-serif;
}

.FirstComputerHomeButtonUnit2ChoiceBoard {
  /* margin-left: auto; */
  /* margin-right: auto; */
  margin-top: 60px;
  padding: 15px;
  font-size: 15px;
  background-color: black;
  /* border-radius: 15px; */
  color: white;
  border: 4px solid black;
}

.FirstComputerHomeButtonUnit2ChoiceBoard:hover {
  cursor: pointer;
  background-color: white;
  color: black;
}


/* .content { */
  /* background-color: aqua; */

/* 
.wholeBody {
  margin-top: -6%;
  padding-top: 6%;
  margin-right: -1%;
  margin-left: -1%;
  padding-left: 1%;
  padding-right: 1%;
} */


.mainBodyFIRSTCOMPUTER_Unit2ChoiceBoard {
  /* COLOURS */

  /* --UNIT2CHOICEBOARDbody-color: #e4e9f7;
  --UNIT2CHOICEBOARDbodysidebar-color: #FFF;
  --UNIT2CHOICEBOARDbodyprimary-color: #695CFE;
  --UNIT2CHOICEBOARDbodyprimary-color-light: #F6F5FF;
  --UNIT2CHOICEBOARDbodytoggle-color: #DDD;
  --UNIT2CHOICEBOARDbodytext-color: #707070; */
  --UNIT2CHOICEBOARDbody-color: #BDD9BF;
  --UNIT2CHOICEBOARDbodysidebar-color: #FFF;
  --UNIT2CHOICEBOARDbodyprimary-color: #412234;
  --UNIT2CHOICEBOARDbodyprimary-color-light: #F6F5FF;
  --UNIT2CHOICEBOARDbodytoggle-color: #DDD;
  --UNIT2CHOICEBOARDbodytext-color: #707070;

  /* TRANSITIONS */
  --UNIT2CHOICEBOARDbodytran-03: all 0.2s ease;
  --UNIT2CHOICEBOARDbodytran-03: all 0.3s ease;
  --UNIT2CHOICEBOARDbodytran-04: all 0.4s ease;
  --UNIT2CHOICEBOARDbodytran-05: all 0.5s ease;
}

/* REUSABLE CSS */
.sidebarFIRSTCOMPUTER_Unit2ChoiceBoard .textNavBarFIRSTCOMPUTER_Unit2ChoiceBoard {
  font-size: 16px;
  font-weight: 500;
  color: var(--UNIT2CHOICEBOARDbodytext-color);
  transition: var(--UNIT2CHOICEBOARDbodytran-03);
  /* white-space: nowr; */
  opacity: 1;
}

.sidebarFIRSTCOMPUTER_Unit2ChoiceBoard .imageNavBarFIRSTCOMPUTER_Unit2ChoiceBoard {
  min-width: 60px;
  display: flex;
  align-items: center;
}

/* .sidebarFIRSTCOMPUTER_Unit2ChoiceBoard .image-textFIRSTCOMPUTER_Unit2ChoiceBoard */
.material-symbols-outlined {
  
  font-variation-settings: 
  'FILL' 0.8,
  'wght' 400,
  'GRAD' 0,
  'opsz' 48
}

/* SIDEBAR */

.headerFIRSTCOMPUTER_Unit2ChoiceBoard {
  position: relative;
}

.sidebarFIRSTCOMPUTER_Unit2ChoiceBoard {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 250px;
  padding: 10px 14px;
  background: var(--UNIT2CHOICEBOARDbodysidebar-color);
  transition: var(--UNIT2CHOICEBOARDbodytran-05);
}

.sidebarFIRSTCOMPUTER_Unit2ChoiceBoard.closeSidebarFIRSTCOMPUTER_Unit2ChoiceBoard {
  width: 60px;
}


.sidebarFIRSTCOMPUTER_Unit2ChoiceBoard.closeSidebarFIRSTCOMPUTER_Unit2ChoiceBoard .textNavBarFIRSTCOMPUTER_Unit2ChoiceBoard {
  opacity: 0;
}

.mainBodyFIRSTCOMPUTER_Unit2ChoiceBoard {
  height: 100vh;
  background-color: var(--UNIT2CHOICEBOARDbody-color);
}

.image-textFIRSTCOMPUTER_Unit2ChoiceBoard {
  display: flex;
  align-items: center;

}


.toggleNavBarFIRSTCOMPUTER_Unit2ChoiceBoard {
  position: absolute;
  top: 50%;
  right: -25px;
  transform: translateY(-50%) rotate(180deg);
  height: 25px;
  width: 25px;
  background: var(--UNIT2CHOICEBOARDbodyprimary-color);
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  color: var(--UNIT2CHOICEBOARDbodysidebar-color);
  font-size: 22px;
}

.sidebarFIRSTCOMPUTER_Unit2ChoiceBoard.closeSidebarFIRSTCOMPUTER_Unit2ChoiceBoard .toggleNavBarFIRSTCOMPUTER_Unit2ChoiceBoard {
  transform: translateY(-50%);
}

.navLinkNavBarFIRSTCOMPUTER_Unit2ChoiceBoard {
  border-radius: 10px;
  height: 50px;
  /* background: red; */
  margin-top: 10px;
  list-style: none;
  display: flex;
  align-items: center;
  
}

.breakLine1navBarFIRSTCOMPUTER_Unit2ChoiceBoard {
  margin-top: 50px;
  margin-bottom: 50px;
  opacity: 0%;
}

.iconNavBarFIRSTCOMPUTER_Unit2ChoiceBoard {
  display: flex;
  align-items: center;
  font-size: 20px;
  color: var(--UNIT2CHOICEBOARDbodytext-color);
  justify-content: center;
  min-width: 60px;
 
}

.navTextNavBarFIRSTCOMPUTER_Unit2ChoiceBoard {
  color: var(--UNIT2CHOICEBOARDbodytext-color);
  transition: var(--UNIT2CHOICEBOARDbodytran-03);
}

.linksNavBarFIRSTCOMPUTER_Unit2ChoiceBoard {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  text-decoration: none;
  transition: var(--UNIT2CHOICEBOARDbodytran-04);
}

.linksNavBarFIRSTCOMPUTER_Unit2ChoiceBoard:hover {
  background: var(--UNIT2CHOICEBOARDbodyprimary-color);
  color: var(--UNIT2CHOICEBOARDbodysidebar-color);
}

.navLinkNavBarFIRSTCOMPUTER_Unit2ChoiceBoard:hover Link{
  color: var(--UNIT2CHOICEBOARDbodysidebar-color);
  
}

.sidebarFIRSTCOMPUTER_Unit2ChoiceBoard li .linksNavBarFIRSTCOMPUTER_Unit2ChoiceBoard .iconNavBarFIRSTCOMPUTER_Unit2ChoiceBoard,
.sidebarFIRSTCOMPUTER_Unit2ChoiceBoard li .linksNavBarFIRSTCOMPUTER_Unit2ChoiceBoard .textNavBarFIRSTCOMPUTER_Unit2ChoiceBoard {
  color: var(--UNIT2CHOICEBOARDbodytext-color);
  transition: var(--UNIT2CHOICEBOARDbodytran-02);
}

.sidebarFIRSTCOMPUTER_Unit2ChoiceBoard li .linksNavBarFIRSTCOMPUTER_Unit2ChoiceBoard:hover .iconNavBarFIRSTCOMPUTER_Unit2ChoiceBoard,
.sidebarFIRSTCOMPUTER_Unit2ChoiceBoard li .linksNavBarFIRSTCOMPUTER_Unit2ChoiceBoard:hover .textNavBarFIRSTCOMPUTER_Unit2ChoiceBoard {
  color: var(--UNIT2CHOICEBOARDbodysidebar-color);
}


.mainBodyFIRSTCOMPUTER_Unit2ChoiceBoard {
  padding-left: 150px;
  background-color: var(--UNIT2CHOICEBOARDbody-color);
  padding-top: 30px;
  padding-bottom: 30px;
}

.HistoricalTimelineFIRSTCOMPUTER_Unit2ChoiceBoard {
  font-size: 75px;
  /* margin-top: 50px; */
  margin-bottom: 50px;
  text-align: center;
  font-family: 'Prosto One', cursive;
}

.vertical-timeline-element-titleFIRSTCOMPUTER_Unit2ChoiceBoard {
  padding-top: 0.25em;
}

.vertical-timeline-element-content {
  box-shadow: 0 0.25em 0.5em 0 rgba(0, 0, 0, 0.25), 0 0.4em 1.25em 0 rgba(0, 0, 0, 0.15) !important;
  padding: 2em 3em !important;
}

.dateFIRSTCOMPUTER_Unit2ChoiceBoard {
  color: var(--UNIT2CHOICEBOARDbodyprimary-color);
}


#descriptionFIRSTCOMPUTER_Unit2ChoiceBoard {
  margin: 1.5em 0 2em 0;
  font-size: 13px;
}

.mainBodyFIRSTCOMPUTER_Unit2ChoiceBoard {
  background-color: var(--UNIT2CHOICEBOARDbody-color);
  height: auto;
  min-height: 100%;
}

.timelineImageFIRSTCOMPUTER_Unit2ChoiceBoard {
  max-width: 100%;
}

#ImgdescriptionFIRSTCOMPUTER_Unit2ChoiceBoard {
  margin-bottom: 20px;
  font-size: 13px;
}

.HistoricalTimelineWorldWarFIRSTCOMPUTER_Unit2ChoiceBoard {
  background-color: white;
  padding: 50px;
}

.BombeHeadingRedFIRSTCOMPUTER_Unit2ChoiceBoard {
  margin-top: 70px;
  text-align: center;
  margin-bottom: 35px;
  font-size: 30px;
  font-family: 'Prosto One', cursive;
  color: #8d2424;

}

.paragraphsFIRSTCOMPUTER_Unit2ChoiceBoard {
  line-height: 1.7;
  color: #2E4052;
}

.BombeTitleFIRSTCOMPUTER_Unit2ChoiceBoard {
  color: #2B2C28;
}

.BombeLineBreakFIRSTCOMPUTER_Unit2ChoiceBoard {
  color: #2B2C28;
  margin-top: 40px;
}

.HistoricalTimelinePageElementsFIRSTCOMPUTER_Unit2ChoiceBoard {
  width: 90%;
}

.timelineParagraphFIRSTCOMPUTER_Unit2ChoiceBoard {
  margin-left: 50px;
  margin-right: 50px;
  margin-bottom: 50px;
}

.WorldWar2ImagesDIVFIRSTCOMPUTER_Unit2ChoiceBoard {
  width: 100%;
  align-items: center;
}

.WorldWar2ImagesFIRSTCOMPUTER_Unit2ChoiceBoard {
  width: 50%;
  align-self: center;
  margin-left: 25%;
  margin-right: 25%;
  margin-top: 50px;
}


.referencesFIRSTCOMPUTER_Unit2ChoiceBoard {
  margin: 25px;
  text-indent: -25px;
}

.homeLinkHOMEFIRSTCOMPUTER_Unit2ChoiceBoard {
  text-decoration: none;
}

.GradShow2023 {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 100px;
  background-color: #BDD9BF;
  min-height: 100%;
}

.BestFriendSkitGradShow2023 {
  padding-top: 40px;
}

.GradShowEighteenSkitGradShow {
  padding-top: 30px;
}

.ArnabImage {
  width: 25%;
}