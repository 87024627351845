@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@500&display=swap');
.content {
    /* margin-top: -50px;
    margin: -50px;
    padding: 0;
    background-color: blue;
    margin-bottom: auto;
    min-height: auto; */
}

.PageTitle {
    font-family: 'Roboto', sans-serif;
    text-align: center;
    /* padding-top: 5px; */
    /* padding-bottom: 5px;
     */
    margin: 50px;
    font-size: 40px;
}

.SeatingImage {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 75%;
    /* margin-top: 80px; */
}

.FormFill {
    display: block;
    margin-left: auto;
    margin-right: auto;
    margin-top: 50px;
    margin-bottom: 50px;
    /* display: block;
    margin-left: auto;
    margin-right: auto;*/
    /* text-align: center;  */
    /* padding-left: 15%; */
    /* display: flex;
    justify-content: center;
    align-items: center; */
    /* padding: 10px; */
    /* margin: 10px; */
    font-family: 'Roboto', sans-serif;
    font-weight: 50;
    /* border: solid black; */
    width: 50%;
    background: rgb(0,0,0,0.05);
    padding: 30px 50px;
    /* color: white; */
}

.FormFill label{
    /* padding: 15px; */
    padding-top: 20px;
}

.FormItem {
    margin-top: 15px;
}


.FormFill label input {
    /* margin-bottom: 20px; */
    margin-top: 5px;
    width: 100%;
    height: 50px;
    /* background: salmon; */
    /* margin: 30px 0; */
    border: none;
    outline: none;
    background: transparent;
    border: 2px solid rgb(0,0,0,0.6);
    /* border-radius: 40px; */
    font-size: 16px;
    /* color: white; */
    padding-left: 20px;
}

.FormFill label select {
    margin-left: 20px;
}

.submitBtn {
    width: 100px;
    background-color: black;
    color: white;
    border: none;
    height: 35px;
}

.submitBtn:hover {
    background-color: transparent;
    border: solid black;
    color: black;
    cursor: pointer;
}

.MakeChanges {
    text-align: center;
    align-items: center;
    margin-bottom: 50px;
    text-decoration: none;
    /* color: aqua; */
}

.MakeChanges:visited { 
    /* color: lightblue; */
    /* color: aqua; */
}

.MakeChangesText{
    /* color: aqua; */
}

.MakeChangesText:hover {
    /* color: aqua */
}